import { useState, useEffect} from 'react';
import { useToken } from './useToken';

export const useUser = () => {
    const [token] = useToken();

    const getPayloadFromToken = token => {
        const encodedPayload = token.split('.')[1];
        /* from https://stackoverflow.com/questions/6182315/how-can-i-do-base64-encoding-in-node-js 
            because "atob" in tutorial is deprecated - although the fix below generated an error so we are using atob?*/
        //return JSON.parse(Buffer.from(encodedPayload,'base64').toString('ascii'));
        return JSON.parse(atob(encodedPayload));
    }
    const [user, setUser] = useState(() => {
        if (!token) return null;
        return getPayloadFromToken(token);
    });

    useEffect(() => {
        if (!token) {
            setUser(null);

        } else {
            setUser(getPayloadFromToken(token));
        }
        
    }, [token]);
    return user;
}