import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useToken } from '../auth/useToken';
import { useQueryParams } from '../util/useQueryParams';

export const LogInPage = () => {

    const [, setToken] = useToken();

    const [emailValue, setEmailValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState('');
    const [googleOauthUrl, setGoogleOauthUrl] = useState('');
    const { token: oauthToken} = useQueryParams();

    useEffect(() => {
        const loadOauthUrl = async () => {
            try {
                const response = await axios.get('/auth/google/url');
                const { url } = response.data;
                setGoogleOauthUrl(url);
            }
            catch (e) {
                console.log(e);
            }
        }
        loadOauthUrl();
    },[]);

    useEffect(() => {
        if (oauthToken) {
            setToken(oauthToken);
            history.push('/');
        }

    }, [oauthToken, setToken, history]);

    const onLogInClicked = async () => {
        const response = await axios.post('/api/login', {
            email: emailValue,
            password: passwordValue,
    });
        const { token } = response.data;
        setToken(token);
        history.push('/');
    }

    return (
        <div className="content-container">
            <h1>Log In</h1>
            {errorMessage && <div className="fail">{errorMessage}</div>}
            <input 
                value={emailValue}
                onChange={e => setEmailValue(e.target.value)}
                placeholder="someone@gmail.com" />

            <input
                placeholder="password"
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                type="password"/>
            <hr />
            <button 
                disabled={!emailValue || !passwordValue}
                onClick={onLogInClicked}>Log In </button>
            <button onClick={() => history.push('/forgot-password')}>Forgot your password?</button>
            <button onClick={() => history.push('/signup')}>Don't have an account? Sign Up!</button>
            <button 
                disabled={!googleOauthUrl}
                onClick={() => { window.location.href = googleOauthUrl}}>Log in with Google</button>

        </div>
    )
}